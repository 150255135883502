/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
body {
  font-family: 'Outfit', sans-serif !important;
}

option {
  color: black;
}
